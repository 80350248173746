<template>
    <div>
        <div class="header">
            <div class="title">
                <div style="display: flex;align-items: center;">
                    <span v-if="!isEdit" style="font-size: 28px;font-weight: bold;">{{ formData.name }}</span>
                    <a-input v-else v-model="formData.name"></a-input>
                    <a-icon v-if="!isEdit" @click="isEdit = true" style="font-size: 28px;margin-left: 20px;" type="edit" />
                    <a-icon v-else @click="isEdit = false" style="font-size: 28px;margin-left: 20px;" type="check-circle"
                            theme="filled" />
                </div>

            </div>
            <div class="buttons">
                <a-button-group>
                    <a-button type="primary" @click="saveJson()" icon="save">保存</a-button>
                    <a-button @click="close()" icon="rollback">取消</a-button>
                </a-button-group>
            </div>
        </div>
        <div>
            <k-form-design @save="saveJson" ref='kfd' :show-head="false" :title="value.name" showToolbarsText
                           :toolbars="['save', 'preview', 'importJson', 'exportJson', 'reset', 'close', 'undo', 'redo']">
                <template slot="left-action">
                    <a-tooltip @click="toggleFullscreen" :title="fullscreen ? '缩小' : '全屏'">
                        <a>
                            <a-icon type="fullscreen" />
                            <span v-if="!fullscreen">全屏</span>
                            <span v-if="fullscreen">缩小</span>
                        </a>
                    </a-tooltip>
                </template></k-form-design>
        </div>
    </div>
</template>

<script>
import utils from '@/common/utils';
import request from '@/config/request';
export default {
    data() {
        return {
            formData: {
                content: '',
                fields: ''
            },
            fullscreen: false,
            isEdit: false,
        };
    },
    methods: {
        saveJson() {
            //过滤字段
            const fields = this.$refs.kfd.getFieldSchema();
            if (fields) {
                fields.forEach((item, i) => {
                    if (item.options.field == false) {
                        fields.splice(i, 1);
                    }
                });
            }
            this.formData.fields = JSON.stringify(fields);
            const data = this.$refs.kfd.getValue();
            data.list = this.checkDisable(data.list);
            this.formData.content = JSON.stringify(data);
            this.doSave();
        },
        // 提交数据
        doSave() {
            console.log(this.formData);
            utils.showSpin();
            request.post('/platform/approve/form/save', this.formData).then(ret => {
                console.log(ret);
                this.formData.formId = ret.data;
                this.$emit('success');
            });
        },
        //检查数据
        checkDisable(list) {
            list.forEach((item, i) => {
                if (item.columns) {
                    item.columns.forEach((child) => {
                        child.list = this.checkDisable(child.list);
                    });
                }
                //未设置默认值
                if (item.options.maxLength === "" || item.options.maxLength === null) {
                    item.options.maxLength = 100000;
                }
            });
            return list;
        },
        close() {
            this.$emit('close');
            app.$emit('fullscreen', true);
            this.fullscreen = true;
        },
        // 清空
        clearAll() {
            this.$refs.kfd.handleReset();
        },
        // 全屏
        toggleFullscreen() {
            this.fullscreen = !this.fullscreen;
            app.$emit('fullscreen');
        }
    },
    watch: {
        value: {
            handler(v) {
                if (!utils.isEmpty(v.content)) {
                    const json = JSON.parse(v.content);
                    this.$nextTick(() => {
                        this.$refs.kfd.handleSetData(json);
                    });
                }
                this.formData = v;
            },
            deep: true,
            immediate: true
        }
    },
    // model: {
    //     prop: 'value',
    //     event: 'input'
    // },
    props: {
        value: {
            type: Object,
            default: () => {
                return {
                    formId: '',
                    name: '',
                    content: '',
                };
            }
        }
    }
};
</script>

<style>
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #FFF;
    padding: 10px 0;
}
</style>